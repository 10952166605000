import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import commerce from "../util/CommerceJS";

function Cart() {

    const [cart, setCart] = useState(null)

    const fetchCart = () => {
        commerce.cart.retrieve().then((cart) => {
            setCart(cart)
        });
    }

    useEffect(() => {
        fetchCart()
    }, [])

    const removeProduct = (id, event) => {
        event.target.setAttribute('aria-busy', 'true')
        commerce.cart.remove(id)
        .then((response) => setCart(response))
    }

    if(cart) {
        return (
            <main className="container">
            <Nav/>
            <div className="content">
                <article>
                    <h2>Your Cart</h2>
                    <div className="auto-grid">
                        {cart.line_items.map(({ name, id, image, price, description, quantity }) => (
                            <article className="product">
                                <img className="product-img" src={ image.url } alt="Product" width={400} height={400}></img>
                                <h2>{name} (x{quantity})</h2>
                                <h3>${price.formatted}</h3>
                                <button className="secondary" onClick={(event) => {removeProduct(id, event)}}><i class="fa-sharp fa-solid fa-trash"></i> Remove</button>
                                <div>{description}</div>
                            </article>
                            ))}
                    </div>
                    <h3>Subtotal: ${cart.subtotal.formatted}</h3>
                    <a href={cart.hosted_checkout_url}><button onClick={(event) => event.target.setAttribute('aria-busy', 'true')}><i class="fa-solid fa-cart-shopping"></i> Checkout</button></a>
                </article>
            </div>
            <Footer/>
            </main>
        )
    }

    return (
    <main className="container">
        <Nav/>
        <h2>Loading cart...</h2>
        <Footer/>
    </main>
    );
  }
  
export default Cart;