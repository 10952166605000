import Nav from "../components/Nav";
import Footer from "../components/Footer";

function HowTo() {
    return (
    <main className="container">
        <Nav/>
        <div className="content">
            <h2>How-To</h2>
            <h2>The Foundation of Wet Shaving</h2>
            <p>Shaving is an art, that requires time! It is worth it to refine your technique and use quality products which can make shaving a pleasurable experience. Remember, how you shave affects the way you look and how you feel about yourself.</p>
            <h2>Water - The Hotter the Better</h2>
            <p>The first essential step to a perfect shave is water and warmth. When hair absorbs hot water, it becomes softer and easier to cut and with warmth the skin and facial muscles become relaxed, making shaving so much easier – thus the best time to shave is after a bath or shower. A soaked folded flannel or small towel in hot water wrapped around the face for thirty seconds or more will also work.</p>
            <h2>To Pre-Shave or Not to Pre-Shave</h2>
            <p>Those who desire a smooth shave may want to use a Pre- Shave product; these products protect the skin and help the razor to glide smoothly across the face. Massaging the Pre-Shave against the growth of the beard also helps to lift the beard in readiness for the lather.</p>
            <h2>Building the Lather</h2>
            <p>When using our shave creams, you can mix in the container or you can place a modest amount in a shave mug or scuttle, dip the brush into hot water and using a circular motion to build up a rich creamy lather on the brush. Wet the face, and again with a circular motion apply the lather to the beard, allowing the brush to lift the beard, making the hairs stand proud. The brush may be dipped lightly into hot water if more moisture is required in the lather. If using shaving soap, dip the brush in hot water and use a similar circular motion on the soap to create a rich lather.</p>
            <h2>Executing the Shave</h2>
            <p>A good quality razor blade that has been warmed under hot running water, shave the face in the direction of the beard growth, rinsing the blade in hot water frequently. Never shave ‘against the grain’ of the beard. In awkward areas such as the chin and under the nose, the blade can be moved sideways across the growth – but never against the hair. This pulls the skin in the wrong direction, causing small cuts and ‘grazing’ to the skin and is the most common cause of ‘razor burn’, in-grown hairs and shaving rash. Rinse the face thoroughly with cool water and pat dry with a soft towel.</p>
            <h2>General Skin Care</h2>
            <p>A good wet shave exfoliates and cleanses the skin, leaving a healthy clean appearance. Newly exfoliated skin needs to be protected from the elements, so for healthy skin it is important that men use an after-shave moisturiser. Products containing alcohol should not be applied to the skin directly after shaving, as this may inflame the skin and cause dryness. For best results, cologne and other fragrances should be applied to the ‘hot spots’ behind the earlobes and on the sides of the neck.</p>
            <h2>Tips</h2>
            <ul>
                <li>Shower or bathe before shaving, or warm the face with a hot flannel.</li>
                <li>Use plenty of hot water and shave in a warm environment.</li>
                <li>Protect the skin with a moisturiser.</li>
                <li>Use a quality badger brush with good shaving cream or soap.</li>
                <li>Brush in a circular motion to lift the beard.</li>
                <li>Shave with the beard, never against the grain.</li>
                <li>Rinse the blade frequently in hot water.</li>
                <li>Rinse face well with cool water and gently pat dry.</li>
                <li>After shaving use a moisturiser.</li>
                <li>Avoid applying alcohol-based products to the face after shaving.</li>
            </ul>
            <h2>Brush and Razor Care</h2>
            <p>After shaving, rinse your brush and razor thoroughly to remove soap and flick to remove most of the water. Brushes are best hung on a brush stand so that water can move away from the base of the hairs. To avoid mildew, do not keep damp brushes in a closed cupboard or washbag for any length of time. If your brush becomes clogged with soap, soak in a mild solution of borax and water until clean.</p>
        </div>
        <Footer/>
    </main>
    );
  }
  
export default HowTo;
  