import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Received() {
    return (
    <main className="container">
        <Nav/>
        <div className="content">
            <h2>Message received</h2>
            <p>Thank you for contacting us! We'll get back to you as soon as possible.</p>
        </div>
        <Footer/>
    </main>
    );
}
  
export default Received;
  