function Footer() {
    return (
    <footer>
        <hr/>
        <p><a href="https://www.facebook.com/Citadel-Shave-Soap-108162532191188"><i className="fa-brands fa-facebook"></i> Facebook Page</a></p>
        <p>© Citadel Shave & Soap. Created by <a href="https://basanites.xyz">Sebastian</a>. <br/></p>
    </footer>
    );
  }
  
  export default Footer;
  