import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Error() {
    return (
    <main className="container">
        <Nav/>
        <div className="content">
            <h2>Error</h2>
            <p>Sorry, we couldn't find that page. Click <Link to={'/'}>here</Link> to go back home.</p>
        </div>
        <Footer/>
    </main>
    );
}
  
export default Error;
  