import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home';
import Error from './pages/Error';
import About from './pages/About';
import FAQ from './pages/FAQ';
import HowTo from './pages/HowTo';
import Contact from './pages/Contact';
import Received from './pages/Received';
import Cart from './pages/Cart';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
    errorElement: <Error/>
  },
  {
    path: "/about",
    element: <About/>,
  },
  {
    path: "/faq",
    element: <FAQ/>,
  },
  {
    path: "/how-to",
    element: <HowTo/>,
  },
  {
    path: "/contact",
    element: <Contact/>,
  },
  {
    path: "/cart",
    element: <Cart/>,
  },
  {
    path: "/received",
    element: <Received/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
