import { Link } from "react-router-dom";

function Nav() {
    return (
    <nav style={{ flexWrap: 'wrap', marginTop: '8%' }}>
        <ul>
            <li><h1><Link to={'/'}><img alt="Logo" src="/assets/images/icononly_transparent_nobuffer.png" width="60" height="60"></img></Link> <strong>CITADEL</strong> <p>Shave & Soap</p></h1></li>
        </ul>
        <ul>
            <li><Link to={'/'}>Home</Link></li>
            <li><Link to={'/about'}>About</Link></li>
            <li><Link to={'/faq'}>FAQ</Link></li>
            <li><Link to={'/how-to'}>How-To</Link></li>
            <li><Link to={'/contact'}>Contact</Link></li>
            <li><Link to={'/cart'}><i class="fa-solid fa-cart-shopping"></i> Cart</Link></li>
        </ul>
    </nav>
    );
  }
  
  export default Nav;
  