import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { useEffect, useState } from "react";
import commerce from "../util/CommerceJS";
import { useNavigate } from "react-router-dom";

function Home() {

    const navigate = useNavigate();

    const [products, setProducts] = useState(null)

    useEffect(() => {
        commerce.products.list().then((products) => {
            setProducts(products.data)
        })
    }, [])

    const addToCart = (id, event) => {
        event.target.setAttribute('aria-busy', 'true')
        commerce.cart.add(id, 1)
        .then(() => navigate('/cart'))
    }

    /* When you're ready to start accepting sales, paste this in: <button onClick={(event) => addToCart(id, event)}><i class="fa-solid fa-cart-shopping"></i> Add to Cart</button> */

    return (
    <main className="container">
        <Nav/>
        <h2>Products</h2>
        <p>These products are currently unavailable, but we'll have stock by May!</p>
        {!products ? (
            <h3>Loading products...</h3>
        ) : (
            <>
            <div className="auto-grid content">
                {products.map(({ name, id, image, price, description }) => (
                    <article className="product">
                        <img src={ image.url } alt="Product" width={800} height={800}></img>
                        <h2>{name}</h2>
                        <h3>${price.formatted}</h3>
                        <hr/>
                        <div dangerouslySetInnerHTML={{ __html: description }}></div>
                    </article>
                    ))}
            </div>
            </>
        )}
        <Footer/>
    </main>
    );
  }
  
export default Home;
  