import Footer from "../components/Footer";
import Nav from "../components/Nav";

function FAQ() {
    return (
    <main className="container">
        <Nav/>
        <div className="content">
            <h2>FAQ</h2>
            <details>
                <summary><strong>Do you use Palm Oil?</strong></summary>
                <p>We do use Palm Oil in our products. However it is not easy to produce certain products without using Palm Oil. We would not want to compromise our products and potentially the health of our customers, by replacing the Palm Oil with harmful chemicals. Any Palm Oil we use comes from <a href="https://rspo.org/">RSPO certified sources</a>.</p>
            </details>
            <details>
                <summary><strong>What Is Triethanolamine?</strong></summary>
                <p>Triethanolamine, often abbreviated as TEA, is a viscous organic compound that is both a tertiary amine and a triol. A triol is a molecule with three alcohol groups.
                    Triethanolamine is used primarily as an emulsifier and surfactant. It is a common ingredient in formulations used for both industrial and consumer products. The triethanolamine neutralizes fatty acids, adjusts and buffers the pH, and solubilises oils and other ingredients that are not completely soluble in water. Some common products in which triethanolamine is found are liquid laundry detergents, dishwashing liquids, general cleaners, hand cleaners, polishes, metalworking fluids, paints, shaving cream and printing inks.
                    I don't know about you, but I wouldn't want to use a shaving cream that contains a component that is used in paints and printing inks.</p>
            </details>
            <details>
                <summary><strong>What we use!</strong></summary>
                <ul>
                    <li>Tallow - is a rendered form of beef or mutton fat, primarily made up of triglycerides.</li>
                    <li>Steric Acid - stearic acid is one of the most common saturated fatty acids found in nature following palmitic acid.</li>
                    <li>Myristic Acid - Plant Derived - but can also be found in Butterfat in Bovine Milk.</li>
                    <li>Coconut Oil - is an edible oil derived from the wick, meat, and milk of the coconut palm fruit.</li>
                    <li>Palm Oil - is an edible vegetable oil derived from the mesocarp (reddish pulp) of the fruit of the oil palms.</li>
                    <li>Shea Butter -  is a fat extracted from the nut of the African shea tree (Vitellaria paradoxa).</li>
                    <li>Cocoa Butter - also called theobroma oil, is a pale-yellow, edible fat extracted from the cocoa bean.</li>
                    <li>Glycerin - obtained from plant sources where it occurs in triglycerides, esters of glycerol with long-chain carboxylic acids.</li>
                    <li>Sodium Lactate - Sodium Salt of Lactic Acid produced by the fermentation of a sugar source such as corn or beets.</li>
                    <li>Allantoin - Allantoin, also known as aluminum dihydroxy allantoinate, is an extract from the comfrey plant.</li>
                    <li>Lanolin - also called wool yolk, wool wax, or wool grease, is a wax secreted by the sebaceous glands of wool-bearing animals.</li>
                    <li>Castor Oil - is a vegetable oil pressed from castor beans.</li>
                    <li>Castor Jelly - Castor Jelly is a vegetable-based blend of (INCI) Ricinus Communis (Castor) Seed Oil and Hydrogenated Castor Oil.</li>
                    <li>Argan Oil - is a plant oil produced from the kernels of the argan tree (Argania spinosa L.)</li>
                    <li>Jojoba Oil - is the liquid produced in the seed of the Simmondsia chinensis (jojoba)[1] plant.</li>
                    <li>Sodium Hydroxide - is used to invert the fats during saponification of soap. (Hard Soap)</li>
                    <li>Potassium Hydroxide - is used to invert the fats during saponification of soap. (Soft Soap)</li>
                    <li>Tocopherol - are a class of organic chemical compounds (more precisely, various methylated phenols), many of which have Vitamin E.</li>
                    <li>Fragrance - can be Essential Oils or Fragrance Oils.</li>
                    <li>Pine Resin - derived from pine trees.</li>
                </ul>
            </details>
        </div>
        <Footer/>
    </main>
    );
  }
  
export default FAQ;
  