import Nav from "../components/Nav";
import Footer from "../components/Footer";

function About() {
    return (
    <main className="container">
        <Nav/>
        <div className="content">
            <h2>Our Story</h2>
            <p>The 'barber shop experience', but in the comfort of your own home. A close shave, using a luxurious shaving cream that moisturizes your skin, made with natural ingredients that won't harm you. This is what we were inspired by, and spent years perfecting. 10 years ago, we began our journey in our tiny home-based kitchen, and after much experimenting, we found we created an excellent product to be proud of. This journey started out as a hobby, but has now become a passion!</p>
            <p>Citadel Shave & Soap is steeped in tradition and strength. Our shaving creams are based on the recipes of old, the way our grandfathers and great-grandfathers used to make it. It's an old-world style, with a new world twist. We prefer to focus on high quality, creating small handcrafted artisan batches of supreme product. </p>
            <p>Over the years, we expanded from shaving creams to other grooming products, and we are continuing to create. We look forward to serving this great community and providing you with excellent products.</p>
        </div>
        <Footer/>
    </main>
    );
  }
  
export default About;
  