import Footer from "../components/Footer";
import Nav from "../components/Nav";

function Contact() {

    return (
    <main className="container">
        <Nav/>
        <h2>Contact</h2>
        <div className="content">
            <form action={"https://api.web3forms.com/submit"} method={"POST"}>
                <input type="hidden" name="access_key" value="630f12b1-5d2b-4dad-abb5-72d0f83f9234"></input>
                <input type="checkbox" name="botcheck" class="hidden" style={{ display: 'none' }}></input>
                <input type="hidden" name="redirect" value="https://citadelshave.com/received"></input>

                <input type="text" name="name" placeholder="John Smith" required></input>
                <input type="email" name="email" placeholder="john.smith@email.com" required></input>
                <textarea name="message" placeholder="Hi there!" style={{ resize: 'vertical', height: '250px' }} required></textarea>
                <button id="submit-btn" type="submit"><i class="fa-solid fa-paper-plane"></i> Send</button>
            </form>
        </div>
        <Footer/>
    </main>
    );
  }
  
export default Contact;
  